<template>
  <div class="main-login-block center-block">
    <div>
        <div class="logo" v-html="Logotype"></div>
        <h3 class="title">Change password</h3>
        <div class="form-row">
          <span class="text">Please enter your old password, for security’s sake, and then enter your new password twice so we can verify you typed it in correctly.</span>
        </div>
        <div class="form-row">
          <div class="w-100">
            <input-password v-model="oldPassword"/>
            <div v-if="errorOldPassword" class="error">{{errorOldPassword}}</div>
          </div>
        </div>

        <div class="form-row">
          <div class="w-100">
            <input-password v-model="password"/>
            <div v-if="errorPassword" class="error">{{errorPassword}}</div>
          </div>
        </div>

        <div class="form-row">
          <div class="w-100">
            <input-password v-model="passwordConfirm"/>
            <div class="error">{{errorPasswordConfirm}}</div>
          </div>
        </div>
        
        <div class="form-row" v-if="response">
          <div class="error server">
            {{response}}
          </div>
        </div>

        <div class="form-row space-between">
          <div class="main-button orange no-background no-border " @click="onBack">Cancel</div>
          <div class="main-button" @click="onSet">Change my password</div>
        </div>

    </div>
  </div>
</template>

<style>
.text {
  line-height: 16px;
  letter-spacing: 0.7px;
  color: #858688;
}
</style>

<script>
import { Logotype } from '@/utils/icons'
import InputPassword from '@/components/inputs/InputPassword'
import { urls } from '@/utils/urls.js' 

export default {
  name: 'ChangePassword',
  components: { InputPassword },
  
  data() {
    return {
        Logotype,
        oldPassword: {
            placeholder: 'Old password',
            value: null,
            isValid: false,
            clearable: false,
            required: true,
            readonly: false,
           // maxlength: 5,
            minlength: 8
        },

        password: {
            placeholder: 'New password',
            value: null,
            isValid: false,
            clearable: false,
            required: true,
            readonly: false,
           // maxlength: 5,
            minlength: 8
        },

        passwordConfirm: {
            placeholder: 'Confirm password',
            value: null,
            isValid: false,
            clearable: false,
            required: true,
            readonly: false,
           // maxlength: 5,
            minlength: 8
        },

        isDataLoaded: true,
        errorPasswordConfirm: null,
        errorPassword: null,
        errorOldPassword: null,
        response: null,
        token: null
    }
  },

  computed: {
    isValidForm() {
      if(this.oldPassword.isValid && this.passwordConfirm.isValid && this.password.isValid && this.passwordConfirm.value === this.password.value) return true

      return false
    },

    currentUser() {
        return this.$store.state.currentUser?.user_data
    },
  },

  watch: {
    'passwordConfirm.value'() {
      this.$nextTick(() => {
       if(this.passwordConfirm.value !== this.password.value) {
          this.passwordConfirm.isValid = false
       } 
       else if(this.password.isValid && this.passwordConfirm.value === this.password.value) {
          this.passwordConfirm.isValid = true
       }
     })
    },

    'password.value'() {
      this.$nextTick(() => {
        if(this.passwordConfirm.value !== this.password.value) {
            this.passwordConfirm.isValid = false
        } 
        else if(this.password.isValid && this.passwordConfirm.value === this.password.value) {
              this.passwordConfirm.isValid = true
        }
      })
    }
  },

  created() {
      this.token = this.$route.query?.token || ''
  },

  methods: {
    async fetchSet(query) {
      if(!query || !this.isDataLoaded) return

      this.isDataLoaded = false

      try {
        const token = localStorage.getItem('userAuth')
        const response = await fetch(urls.changePassword + query, {
          method: 'POST', 
          headers: {
            'Authorization': `Bearer ${JSON.parse(token).access_token}`,
            'accept': 'application/json',
            },
        })

        const json = await response.json()
        if(response.status === 200) {
          if(typeof json === 'object' && 'id' in json) this.$router.push('/my-profile') 
        } 
        else if (response.status > 399) {
          if(json?.detail) {
            this.response = json?.detail
          }
        }
        this.isDataLoaded = true

      } catch (error) {
        this.isDataLoaded = true
        console.log(error)
      }
  },

  onBack() {
    this.$router.go(-1) 
  }, 

  onSet() {
      this.showErrors()
      if(this.isValidForm) this.fetchSet(`?old_password=${this.oldPassword.value}&new_password=${this.password.value}`)
    },

    showErrors() {
      const reg = /^\d+$/;
      /*
        Your password can’t be too similar to your other personal information.
      Your password must contain at least 8 characters.
        Your password can’t be a commonly used password.
      Your password can’t be entirely numeric.
      */

        this.errorOldPassword = null
        if(!this.oldPassword.value || this.oldPassword.value.length < 8) 
          this.errorOldPassword = 'Your password must contain at least 8 characters'
        else if(reg.test(this.oldPassword.value))  
          this.errorOldPassword = 'Your password can’t be entirely numeric'
        else if(this.oldPassword.value.includes(this.currentUser.email) || this.oldPassword.value.includes(this.currentUser.first_name)  || this.oldPassword.value.includes(this.currentUser.last_name))  
          this.errorOldPassword = 'Your password can’t be too similar to your other personal information'

        if(this.errorOldPassword) {
          this.oldPassword.isValid = false
        } 
        else {
          this.oldPassword.isValid = true
        }
        
        this.errorPassword = null
        if(!this.password.value || this.password.value.length < 8) 
          this.errorPassword = 'Your password must contain at least 8 characters'
        else if(reg.test(this.password.value))  
          this.errorPassword = 'Your password can’t be entirely numeric'
        else if(this.password.value.includes(this.currentUser.email) || this.password.value.includes(this.currentUser.first_name)  || this.password.value.includes(this.currentUser.last_name))  
          this.errorPassword = 'Your password can’t be too similar to your other personal information'

        if(this.errorPassword) {
          this.password.isValid = false
        }
        else {
          this.password.isValid = true
        }

        this.errorPasswordConfirm = null
        if(!this.passwordConfirm.value || this.passwordConfirm.value.length < 8) 
          this.errorPasswordConfirm = 'Your password must contain at least 8 characters'
        else if(reg.test(this.passwordConfirm.value))  
          this.errorPasswordConfirm = 'Your password can’t be entirely numeric'
        else if(this.passwordConfirm.value.includes(this.currentUser.email) || this.passwordConfirm.value.includes(this.currentUser.first_name)  || this.passwordConfirm.value.includes(this.currentUser.last_name))  
          this.errorPasswordConfirm = 'Your password can’t be too similar to your other personal information'

        if(this.errorPasswordConfirm) {
          this.passwordConfirm.isValid = false
        } 
        else {
          this.passwordConfirm.isValid = true
        }
        



    }
  }
}
</script>
